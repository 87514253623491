<template>
  <div id="agende" class="w-full bg-white text-left">
    <div class="grid grid-cols-1 lg:grid-cols-2">

        <div id="agende-esq" class="-mt-12 mx-8 pt-16 lg:-mt-2 lg:mx-16 lg:pt-32 text-left">
          <h1 class="pt-2 font-light text-ourblue">{{ msg }}</h1>           
          <div class="grid grid-cols-2 my-8 pt-4">
            <div><p style="color:rgb(120 126 123);">Harpi vem para transformar a forma como o setor de suprimentos realiza compras.</p></div>
            <div><a href="mailto:contato@harpi.ai?subject=Contato via Site">Fale com nossa equipe</a></div>
          </div>
        </div>

        <div id="agende-dir" class="p-60">
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'agende-txt',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#agende h1 {
font-family: 'VotorantimSans-Regular', sans-serif;
font-size: 3em;
    font-weight: 400;
    line-height: 1.2em;

}

#agende-dir {    background: #ba1515 url('../assets/iStock-1325566034@2x.jpg') no-repeat center /cover; height: 100%;     min-height: 40vh;}

a {
    line-height: inherit;
    background: #0972A0;
    padding: 20px;
    color: white;
    border-radius: 28px;
    margin-left: 50px;
    text-align: center;
    margin-top: 5px;
    display: block;    
}


@media ( max-width: 767.98px ) {

  #agende h1 {    font-size: 2.5em !important;}    
  
  }

</style>