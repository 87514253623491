<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="modal" @click.stop>

<h2>POLÍTICA DE PRIVACIDADE</h2>
<p><strong>Compart Servicos e Assessorias </strong>(“Nós”) agradece o seu acesso ao Site do harpi em https://www.harpi.com.br (“Site“). Estamos comprometidos com a privacidade do Usuário (“Usuário”, “Titular” ou “Você”), e por isso, desenvolvemos essa Política de Privacidade (“Política de Privacidade”, ou “Política”) que descreve como coletamos, usamos, utilizamos, acessamos, reproduzimos, transmitimos, processamos, arquivamos, compartilhamos ou eliminamos seus dados pessoais ao utilizar a plataforma.</p>
<p>Por favor, leia esta Política de Privacidade cuidadosamente antes de usar o Site. Ao usar o Site, você reconhece que leu esta Política de Privacidade e concorda em se comprometer com ela.</p>
<p>Esta Política de Privacidade não se aplica a informações coletadas por terceiros, incluindo sites, aplicativos ou outro conteúdo que possa ser acessível por meio ou vinculado a este Site. Não nos responsabilizamos pelo conteúdo ou práticas de privacidade em qualquer outro site.</p>
<p>Para mais informações acerca do tratamento de dados pessoais realizado, entre em contato com o Encarregado de Proteção de Dados da Votorantim. A Compart é parte do grupo Votorantim.</p>
<p><strong>CASO VOCÊ NÃO CONCORDE COM QUAISQUER DAS DISPOSIÇÕES DESTA POLÍTICA DE PRIVACIDADE, POR FAVOR NÃO UTILIZE NOSSO SITE, NOSSAS OUTRAS APLICAÇÕES DE INTERNET, NEM CONTRATE OS NOSSOS SERVIÇOS.</strong></p>
<p><strong>Quem é o controlador de seus dados pessoais?</strong></p>
<p>A Compart Serviços e Assessorias LTDA., sociedade inscrita no CNPJ/MF sob nº 30.468.108/0001-74, com sede no Município de São Paulo, Estado de São Paulo, é a empresa controladora dos dados pessoais tratados no âmbito desta Política de Privacidade. Para fins da legislação aplicável, “controlador” é a quem compete as decisões referentes ao tratamento de dados pessoais. Além disso, conforme será detalhado na presente Política de Privacidade, os seus dados pessoais poderão ser compartilhados com a Votorantim, empresas parceiras e investidas da Votorantim para fins de gestão e operação do Site e das demais aplicações de Internet da Compart, bem como para aprimoramento dos serviços prestados pela Compart no contexto do Harpi, hipóteses em que tais empresas também poderão ser controladora de seus dados pessoais.</p>
<p><strong>Dados pessoais que tratamos</strong></p>
<p>A Compart poderá coletar suas informações em vários pontos diferentes e de maneiras diferentes neste Site, conforme abaixo:</p>
<p>Informações fornecidas diretamente pelo Usuário: Nome, endereço, e-mail, telefone, empresa e país.</p>
<p>Informações de navegação e Cookies: Cookies são informações que um site transfere para o computador de um indivíduo para fins de manutenção de registros. Esses cookies serão colocados como um arquivo de texto no navegador do seu computador. Você pode configurar seu navegador para recusar cookies, mas consequentemente poderá não conseguir acessar ou usar partes do Site. Web beacons são pequenos pedaços de código incorporados em páginas da web ou e-mails que ajudam na entrega de cookies. Eles nos ajudam a determinar se uma página da web foi visualizada e, em caso afirmativo, quantas vezes. Também podemos usar web beacons em mensagens de e-mail para determinar o que o destinatário fez com a mensagem. Embora você não possa recusar o recebimento de web beacons ao visitar uma página da Web, pode optar por não os receber por e-mail se desativar as imagens HTML ou recusar as mensagens de e-mail em HTML. Podemos coletar automaticamente outras informações, incluindo tipo de computador, tipo de navegador e plug-ins, idioma, provedor de serviços de Internet (ISP), sistema operacional, habilitação de script java, páginas de referência e saída, marcação de data/hora, dados de fluxo de cliques e cidade e país. Além disso, podemos coletar e registrar automaticamente seu endereço de protocolo da Internet (“endereço IP”). Seu endereço IP é um endereço numérico exclusivo, composto por quatro números separados por pontos, que fornece a localização para um computador individual na Internet. Os endereços IP são usados para designar o computador no qual você está acessando o site. Também podemos obter dados sobre você quando você interage com o Site, por exemplo quando você olha uma página ou envia uma mensagem através do Site.</p>
<p>Registros de acesso e demais informações automatizadas. Podemos coletar e armazenar algumas informações que recebe automaticamente toda vez que o Usuário interage com o Site. Internet Protocol (IP), tipo de navegador, bem como datas e horas das interações do Usuário com as plataformas, são alguns exemplos desta coleta.</p>
<p><strong>Para quais finalidades coletamos seus dados?</strong></p>
<p>Os dados pessoais dos Usuários conforme o caso, são tratados para as seguintes finalidades:</p>
<p>Proporcionar alta eficácia na prestação dos seus serviços e suporte adequado à realização das nossas atividades;</p>
<p>Adequar a aparência e o conteúdo às preferencias do usuário, a fim de proporcionar-lhe acesso mais rápido, agradável e eficaz;</p>
<p>Autenticar acessos;</p>
<p>Buscar segurança adequada aos serviços oferecidos e meios de identificação no caso de usos impróprios ou ilícitos;</p>
<p>Resolver problemas e formular notificações relacionadas ao uso dos sites, plataformas e aplicações de Internet;</p>
<p>Manter os usuários informados sobre os serviços, alterações nos Termos de Uso ou na Política de Privacidade, atualizações ou melhorias nos sites, plataformas e aplicações de Internet, ou ainda sobre o status de suas contas e operações;</p>
<p>Divulgar ações promocionais dos sites, plataformas e outras iniciativas, sempre disponibilizando meios para o usuário cancelar o recebimento de e-mails promocionais (opt out);</p>
<p>Divulgar ações promocionais de empresas parceiras que adotem medidas de proteção à privacidade similares à presente política, mediante anuência prévia do usuário;</p>
<p>Apurar informações estatísticas;</p>
<p>Para prestar e aprimorar o serviço de atendimento aos Titulares no Site;</p>
<p>Aprimorar os serviços e/ou funcionalidades do Site e das plataformas.</p>
<p><strong>Com quem compartilhamos seus dados pessoais?</strong></p>
<p>Poderemos compartilhar seus dados pessoais com terceiros, parceiros de negócios, empresas do mesmo grupo econômico da Votorantim ou empresas controladas pela Votorantim que sejam relevantes para fins de viabilizar a utilização do Site e outras Plataformas pelos Usuários ou viabilizar a prestação dos serviços ao Cliente. Referido compartilhamento ocorre com base nos seguintes critérios e para as finalidades descritas abaixo.</p>
<p>Prestadores de serviços ou parceiros comerciais: Podemos compartilhar seus dados pessoais com prestadores de serviços contratados para fins de (a) fornecimento de software de gestão operacional e outra tecnologia da informação para gestão e cadastro de Clientes. Tais agentes recebem seus dados pessoais com a finalidade específica de prestar serviços à Nós e não possuem quaisquer direitos de uso de seus dados fora dessa hipótese.</p>
<p>Requisição de autoridade competente: Podemos compartilhar seus dados pessoais com terceiros (incluindo órgãos governamentais) a fim de responder a investigações, medidas judiciais, processos judiciais ou investigar, impedir ou adotar medidas acerca de atividades ilegais, suspeita de fraude ou situações que envolvam ameaças em potencial à segurança física de qualquer pessoa ou se de outra maneira exigido pela legislação.</p>
<p>Novos negócios: Se estivermos envolvidos em uma fusão, aquisição ou venda de todos ou de parte de seus ativos, seus dados pessoais poderão ser transferidos para a empresa ou pessoa adquirente. Em circunstâncias nas quais a identidade do controlador de seus dados pessoais se alterar como resultado de qualquer transação, você será notificado de quaisquer escolhas que possa ter acerca de seus dados pessoais.</p>
<p><strong>Por quanto tempo vamos reter seus dados pessoais?</strong></p>
<p>Armazenamos e mantemos suas informações: (i) pelo tempo exigido por lei; (ii) até o término do tratamento de dados pessoais, conforme mencionado abaixo; ou (iii) pelo tempo necessário a preservar o nosso legítimo interesse. Assim, trataremos seus dados, por exemplo, durante os prazos prescricionais aplicáveis ou enquanto necessário para cumprimento de obrigação legal ou regulatória.</p>
<p>O término do tratamento de dados pessoais ocorrerá nos seguintes casos:</p>
<p>Quando a finalidade pela qual os dados pessoais do Titular foram coletados for alcançada e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade;</p>
<p>Quando o Titular estiver em seu direito de solicitar o término do tratamento e a exclusão de seus dados pessoais e o fizer; e</p>
<p>Quando houver uma determinação legal neste sentido.</p>
<p>Nesses casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela presente Política de Privacidade, os dados pessoais serão eliminados.</p>
<p><strong>Seus direitos em relação aos dados pessoais que tratamos sobre você</strong></p>
<p>Você terá determinados direitos em relação aos seus dados pessoais. Tais direitos incluem, mas não se limitam a:</p>
<p>Receber informações claras e completas sobre o tratamento de seus dados pessoais, incluindo sobre as hipóteses de compartilhamento de dados pessoais;</p>
<p>Solicitar o acesso a seus dados pessoais e/ou a confirmação da existência de tratamento de dados;</p>
<p>Solicitar que retifiquemos quaisquer dados pessoais imprecisos, incompletos e desatualizados;</p>
<p>Se opor às atividades de tratamento, solicitar a anonimização e eliminação de dados pessoais, em circunstâncias específicas;</p>
<p>Solicitar a portabilidade de seus dados pessoais;</p>
<p>Revogar o consentimento a qualquer momento, quando a Nós tratarmos seus dados pessoais com base no consentimento; e</p>
<p>Solicitar a revisão de decisões automatizadas que possam afetar seus interesses.</p>
<p>Você poderá exercer tais direitos mediante contato com o Encarregado ou no Site.</p>
<p><strong>Segurança, garantias e precauções de uso do Site</strong></p>
<p>Nós estamos comprometidos em entregar um Site seguro e confiável para você e garantimos tomar as medidas necessárias para proporcionar uma melhor experiência nesse sentido.</p>
<p>O Site do Harpi é um ambiente seguro para que possamos proporcionar um ambiente em que qualquer transmissão de dados seja sigilosa e segura. Este Site incorpora vários procedimentos físicos, eletrônicos e administrativos para proteger a confidencialidade de seus dados pessoais. O acesso às suas informações pessoais é restrito e somente a nossa equipe de suporte de TI, suas afiliadas e seus provedores de serviços que necessitam acessar imprescindivelmente para finalidades específicas têm acesso concedido aos seus dados pessoais. Os servidores em que armazenamos suas informações pessoais são mantidos em um ambiente seguro e protegido pelo escopo de trabalho da área de segurança de informação, que atende os requisitos de segurança ISO27001, além de boas práticas de mercado.</p>
<p>Contudo, ressaltamos que, assim como qualquer interação ocorrida na internet, não é possível garantir um nível de segurança infalível e livre de toda e qualquer ameaça, tendo em vista a rapidez em que novas tecnologias são desenvolvidas. Dentro desse cenário, nos comprometemos a empreender nossos melhores esforços para garantir que o Site esteja, dentro do possível, sempre atualizada e protegida de invasões e falhas.</p>
<p><strong>Transferência Internacional de dados pessoais</strong></p>
<p>Poderemos compartilhar seus dados pessoais internacionalmente com outras empresas do nosso grupo econômico, com terceiros, parceiros de negócios, que sejam relevantes para fins de viabilizar a prestação dos serviços aos Usuários, nos termos da legislação aplicável. O compartilhamento poderá ocorrer na utilização de serviços de infraestrutura de rede e demais fornecedores, como fornecedores de serviço de armazenamento em nuvem. Nestes casos, podemos compartilhar dados pessoais dos Usuários para viabilizar a prestação dos nossos serviços oferecidos e aprimorá-los. Esses fornecedores recebem suas informações com a finalidade específica de prestar serviços à Nós.</p>
<p><strong>Dados pessoais de menores de idade</strong></p>
<p>Não coletamos intencionalmente dados pessoais de menores de 18 anos por meio do Site do Harpi. Se descobrirmos que qualquer registro ou comunicação foram realizados por um menor de idade, esse registro será imediata e permanentemente cancelado e removido de sua base de dados, com ou sem aviso prévio.</p>
<p><strong>Alterações a esta Política de Privacidade</strong></p>
<p>Reservamos o direito de alterar esta Política de Privacidade a qualquer momento, mediante publicação da versão atualizada no endereço https://harpi.ai </p>
 <p></p>
<p>Em caso de alterações relevantes nesta Política de Privacidade, Titular receberá um aviso a esse respeito.</p>
 <p></p>
<p><strong>Interações com sites de terceiros</strong></p>
<p>Podemos disponibilizar links para outros sites na Internet. NÃO NOS RESPONSABILIZAMOS POR ESTES SITES E CONTEÚDOS E, AINDA, NÃO COMPARTILHA, SUBSCREVE, MONITORA, VALIDA OU ACEITA A FORMA COMO ESSES SITES OU FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO COLETAM, PROCESSAM E TRANSFEREM SUAS INFORMAÇÕES PESSOAIS. Recomendamos que o Usuário consulte as respectivas políticas de privacidade de tais sites para se informar adequadamente a respeito do uso de suas informações pessoais por outros sites ou outras ferramentas.</p>
<p><strong>Identificação do Encarregado</strong></p>
<p>Luis Rabello é o encarregado pelo tratamento de dados pessoais (“Encarregado”).</p>
<p>Em caso de dúvidas, acesse o site www.votorantim.com ou entre em contato com o Encarregado por meio do e-mail privacidados@votorantim.com</p>



      </div>
      <div class="close" @click="$emit('close-modal')">
        <img class="close-img" src="../assets/close-icon.svg" alt="" />
      </div>
    </div>
  </template>
  
  <script>
    export default {
  }
  </script>


<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
    text-align: center;
    background-color: #fff;
    height: 80vh;
    width: 70vw;
    margin-top: 5%;
    padding: 30px;
    border-radius: 20px;
    color: black;
    line-height: 1em;
    overflow-y: scroll;
}
.close {
  margin: 4% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h2 {
  font-weight: 500;
  font-size: 12px;
  margin: 20px 0;
}

p {
  font-size: 10px;
  margin: 0 0 10px 0;
}


</style>