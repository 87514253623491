<template>
  <header>
    <img alt="HARPI logo" src="../assets/logo_harpi-header@2x.png" class="logo">
    <nav>
      <div id="btnHamb"><span></span><span></span><span></span></div>
      <ul class="navigation__list" id="showmenu">
        <li><a href="#oquee">O QUE É  A HARPI </a></li>
        <li><a href="#quemsomos">QUEM SOMOS</a></li>
        <li><a href="#beneficios">BENEFÍCIOS</a></li>
        <li><a href="#cases">CASE</a></li>
        <li><a href="#agende">CONTATO</a></li>
      </ul>
    </nav>



  </header>

</template>




<style >

header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: .5rem 1rem;
    background: #004891;
    color: white;    
    padding: 2vh 9vw;
  }

.logo { height: 64px;}  
    p {
      margin-left: 1rem;
    }

nav {
    margin-right: auto;
    margin-left: 10%;
    padding-top: 25px;
    font-family: 'VotorantimSans-Bold', sans-serif; 
    font-weight: 700;
    font-size: 1.1em;
}

  nav ul {
      list-style: none;
    }

  nav ul li {
      display: inline-flex;
      margin-left: 2.1rem;
    }  

  nav ul li a:hover { color: #78C3CE; }




  .showmenu {
      display: none;
  }
  .navigation__list {
      display: flex;
  }
  #btnHamb {
      display: none;
  }
  
  @media ( max-width: 767.98px ) {

    nav {
      margin-left: 50%;
    padding-top: 15px;
}

#btnHamb    span {  width: 35px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    display: block;
    border-radius: 5px;}

    #btnHamb {
      display: block;
  }

  .navigation__list {
      opacity: 0;
  }

  .navigation__list {
      flex-direction: column;
      }
      .navigation__list li {
          vertical-align: center;
          flex: 2;
          margin-bottom: 10px;
      }
      .navigation__list li a {
          border-bottom: 1px solid;
          width: 70%;
          margin: 0 auto;
      }
  .showmenu {
    font-size: 50px;
    display: block;
    text-align: right;
    padding: 10px;
  }
  #btnHamb:hover ~ #showmenu {
    transition: all .5s;
    height: 30vh;
    display: grid;
    margin-left: -80vw;
    /* width: 200px; */
    margin-top: 50px;
    opacity: 1;
  }
  
  #showmenu {
      transition: all .5s;
    height: 0;
  }
      
  
  }

</style>