<template>
  <footer class="bg-gray text-left lg:p-6">
    <div class="w-full">
      <div class="grid md:grid-cols-1 lg:grid-cols-3 p-4">
        <div>
          <div class="grid md:grid-cols-1 lg:grid-cols-2">
            <div class="ml-26"><img alt="HARPI" src="../assets/logo_harpi-footer@2x.png" class="logo"></div>
            <div>
              <nav class="ml-2">
                <ul>
                  <li><a href="#oquee">O QUE É  A HARPI </a></li>
                  <li><a href="#quemsomos">QUEM SOMOS</a></li>
                  <li><a href="#beneficios">BENEFÍCIOS</a></li>
                  <li><a href="#cases">CASE</a></li>
                  <li><a href="#agende">CONTATO</a></li>
                </ul>
              </nav>                
            </div>
          </div>
        </div>
        <div>b</div>
        <div class="text-white lg:ml-14">
          Av. Manuel Bandeira, 291 | 1º andar |<br/>
          Conjunto 21B | Bloco B<br/>
          CEP: 05317-020<br/>
          São Paulo - SP<br/>
          <br/>

<a href="https://www.linkedin.com/company/harpi-procurement-intelligence" target="_blank"><img alt="linkedin" src="../assets/linkedin_icon@2x.png" class="icones" ></a>
<a href="https://api.whatsapp.com/send?phone=+5511974034765&text=Ol%C3%A1!Harpi" target="_blank"><img alt="whatsapp" src="../assets/whatsapp_icon@2x.png" class="icones" ></a>

          <div style="clear:both"></div>
          <br/>

          <button @click="showModal = true">Política de Privacidade</button>
          <SavedModal v-show="showModal" @close-modal="showModal = false" />


        </div>
      </div>
    </div>

  </footer>
</template>



<script>
import { computed } from 'vue'
import SavedModal from './SavedModal.vue'

export default {
  components: {
    SavedModal
  },
  data() {
    return {
      showModal: false,
    }
  },
  
  setup () {
  const year = computed(() => new Date().getFullYear())
  return { year }
}
}
</script>

<style scoped>

footer { font-size: .85em; letter-spacing: .1em; }
.logo {     height: 50px;    margin:6em 3em 0 0;}
  nav {    margin-left: auto;  }
  nav ul {      list-style: none;    }
  nav ul li {      display: block;      margin-left: 2rem;      color: white; padding-bottom: 1em;   }  

.icones {
    width: 25px;
    float: left;
    margin-right: 2em;
}

@media ( max-width: 767.98px ) {
  .logo {    margin:0 !important;}
}


</style>