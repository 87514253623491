<template>
  <app-header />
  <Hero msg="Transformar o modo <br>como organizações <br>fazem suas compras <br>através da inovação." />

<main>
  <!-- O QUE E importando como component -->
  <Oque msg="Harpi é a solução de Procurement Intelligence que nasceu para transformar a maneira como as empresas realizam suas compras." />
  <!-- QUEM SOMOS importando como component -->
  <QuemSomos msg="Símbolo de força e avidez, a harpia ou gavião-real - ave de rapina da fauna brasileira silvestre - possui o espírito de inteligência e visão abrangente que deu origem ao nome Harpi!Somos a solução ideal para empresas que buscam otimizar e automatizar os processos de procurement, reduzindo o tempo de atendimento e melhorando o resultado das negociações, garantindo assim a conformidade com políticas internas e o compliance das aquisições. Com o uso de Inteligência Artificial aplicada ao negócio, possibilitamos tomadas de decisões estratégicas para todo o tipo de compra, incluindo fatores ESG, performance e/ou saúde financeira no processo de equalização das propostas e escolhas de fornecedores."/>
</main>

  <Inovacao msg="Inovação aliada ao negócio que traz" />

  <Beneficios msg="BENEFÍCIOS HARPI" />
  
  <Cases msg="CASE DE SUCESSO" />
  
  <Agende msg="AGENDE UMA DEMONSTRAÇÃO" />

  <app-footer />


</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import Hero from './components/Hero.vue'
import Oque from './components/Oque.vue'
import QuemSomos from './components/QuemSomos.vue'
import Inovacao from './components/Inovacao.vue'
import Beneficios from './components/Beneficios.vue'
import Cases from './components/Cases.vue'
import Agende from './components/Agende.vue'


export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Hero,
    Oque,
    QuemSomos,
    Inovacao,
    Beneficios,
    Cases,
    Agende
    }
}
</script>

<style>


@font-face {
  font-family: VotorantimSans-Regular;
  src: url("./assets/fonts/VotorantimSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: VotorantimSans-Bold;
  font-weight: bold;
  src: url("./assets/fonts/VotorantimSans-Bold.ttf") format("opentype");
}

* {padding: 0; margin: 0}
html { scroll-behavior: smooth; }
body {overflow-x:hidden; font-family: 'VotorantimSans-Regular', sans-serif;   scroll-behavior: smooth;     font-size: 14px; height:100vh; }
h1,h2,h3,h4,h5,h6 {   font-family: 'VotorantimSans-Bold', sans-serif; font-weight: 700 !important;}
a:hover { opacity:.8;}

#app {
  font-family: 'VotorantimSans-Regular', sans-serif;   
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url(assets/HOME_Bkg@2x.png) top center no-repeat /cover;
}

main {
    background: #fff url('./assets/HOME_Bkg@2x.png') no-repeat center /cover;
    min-height: 70vh;    
}

#beneficios { z-index: 9999;}


@media ( max-width: 767.98px ) {

html,body {    overflow-x: hidden;}
.img-solta-1, .img-solta-2 { display: none;}

}


</style>



