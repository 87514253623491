<template>
  <div id="hero" class="w-full">
    <div class="grid grid-cols-1 lg:grid-cols-2">
      <div data-aos="zoom-in" >
        <h1 class="mt-20 ml-0 p-1 lg:m-36 lg:p-22 text-ourblue" v-html="msg"></h1>
      </div>

        <div>
          <!-- <img alt="HARPI" src="../assets/logo.png" height="50"> -->
        </div>
    </div>
  </div>
</template>





<script>
  
export default {
  name: 'hero-txt',
  props: {
    msg: String
  },  
}


</script>


<style scoped>
#hero {
    background: #fff url('../assets/iStock-1365606637@2x.jpg') no-repeat center /cover;
    min-height: 90vh;    
}

#hero h1 {
    text-align: left;
  font-family: 'VotorantimSans-Regular', sans-serif;   
    font-size: 2.5em;
    font-weight: 500 !important;
    line-height: 1.2em;
}

@media ( max-width: 767.98px ) {

#hero {    min-height: 75vw;}
#hero h1{    font-size: 1.3em;}

}

</style>