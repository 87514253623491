<template>
  <div id="beneficios" class="w-full">
    <div class="grid grid-cols-1 lg:grid-cols-2">
      
        <div id="beneficios-esq">

          <div class="beneficios-box bg-gray mx-3 px-8 py-14 lg:mx-6 lg:px-16 lg:py-28 text-justify text-white" data-aos="fade-in">
            <p>A Harpi é uma grande aliada no processo de transformação digital, pois ela consegue unir tecnologia com estratégia de negócios para resolver questões operacionais, tornando o processo de tomada de decisão mais ágil, assertivo e inteligente na hora de efetuar uma compra. </p>
            <a href="https://www.linkedin.com/company/harpi-procurement-intelligence" target="_blank"><img alt="linkedin" src="../assets/linkedin_icon@2x.png" class="icones" ></a>

          </div>
      
        </div>

        <div id="beneficios-dir" class="p-2 text-left">
          <img alt="BENEFÍCIOS HARPI" src="../assets/iStock-1311088152@2x.jpg" class=" -mt-10 -ml-5 "  data-aos="zoom-in" >
          <h1 class="my-10 mx-0 text-white text-left"  data-aos="slide-left" >{{ msg }}</h1>

          <div id="beneficios-dir-box" class="flex text-white" data-aos="slide-down" >    
            <div class="w-1/6"><img alt="Execução Autônoma" src="../assets/BENEFICIOS_Icon-01@2x.png" class="icones" ></div>
            <div class="w-2/6"><p class="pr-7 -ml-5 lg:pr-14 lg:-ml-10">Execução Autônoma de toda cadeia de suprimentos, desde o recebimento da Requisição de compras até a formalização do pedido de compra.</p></div>

            <div class="w-1/6"><img alt="Potencialização do capital humano" src="../assets/BENEFICIOS_Icon-03@2x.png" class="icones" ></div>
            <div class="w-2/6"><p class="pr-7 -ml-5 lg:pr-14 lg:-ml-10">Potencialização do capital humano e redução do esforço operacional, consequentemente, redução de ftes.</p></div>
            
          </div>

          <div id="beneficios-dir-box" class="flex text-white my-10" data-aos="slide-down" >    
            <div class="w-1/6"><img alt="Negociações mais inteligentes" src="../assets/BENEFICIOS_Icon-02@2x.png" class="icones" ></div>
            <div class="w-2/6"><p class="pr-7 -ml-5 lg:pr-14 lg:-ml-10">Negociações mais inteligentes, estratégicas e eficientes.</p></div>

            <div class="w-1/6"><img alt="Garantia do cumprimento de políticas" src="../assets/BENEFICIOS_Icon-04@2x.png" class="icones" ></div>
            <div class="w-2/6"><p class="pr-7 -ml-5 lg:pr-14 lg:-ml-10">Garantia do cumprimento de políticas e procedimentos internos.</p></div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'beneficios-txt',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#beneficios {
    background:#0972A0 url('../assets/BENEFICIOS_Bkg@2x.png') no-repeat center /cover;
}

.beneficios-box { height: 100vh;  }

#beneficios h1 {
    text-align: justify;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1.2em;
}

#beneficios-esq { padding: 0 15% 0; font-size: 1.2em;  }
#beneficios-esq img.icones { height: 35px; margin-top: 10%;}

#beneficios-dir img.icones { height: 45px;}

/* #beneficios-dir-box div:nth-of-type(odd) {background-color:red;}
#beneficios-dir-box div:nth-of-type(even) {background-color:#000; } */
#beneficios-dir-box div p { font-size:1.1em; }



@media ( max-width: 767.98px ) {

#beneficios-esq {    padding: 0 5% 0;  }
.beneficios-box { height: 55vh; font-size: 12px !important;  }
#beneficios h1 {    font-size: 2.5em; }
#beneficios-dir img.icones {    height: 25px !important; }
#beneficios-dir-box div p {    font-size: .8em !important;    line-height: 1.2em !important;}

}

</style>