<template>
  
  <div id="oquee" class="container m-auto">
    <!-- imagem solta -->
    <img alt="" src="../assets/IMG-SOLTO_Azul@2x.png" class="img-solta-1">
    <div class="grid grid-cols-1 lg:grid-cols-2 lg:m-0 lg:p-0 text-left text-white">
    <div><h1 data-aos="fade-left" data-aos-anchor-placement="top-center">O QUE É A HARPI? </h1>
    <p class="oque-sub"  data-aos="fade-right" >{{ msg }}</p>
    </div>
    <div id="oquee-box" class="grid grid-cols-1 lg:grid-cols-2 lg:m-0 lg:p-2 bg-ourblue">
      <!-- topo -->
      <div><img alt="HARPI" src="../assets/logo_harpi@2x.png" class="logo"></div>
      <div><h3>INTELIGÊNCIA ARTIFICIAL APLICADA AO NEGÓCIO</h3></div>
      <!-- linha 1 -->
      <div  data-aos="fade" data-aos-duration="2500" data-aos-delay="500"><img alt="Quote Automation" src="../assets/ICON_Quote@2x.png" class="icones" ><h3>Quote Automation </h3><p>Inteligência Artificial capaz de identificar o vendor list mais adequado para uma Requisição de Compra e disparar automaticamente o envio de RFQs ao mercado.</p></div>
      <div  data-aos="fade" data-aos-duration="2500" data-aos-delay="500"><img alt="Assistente Virtual " src="../assets/ICON_Assistente@2x.png" class="icones" ><h3>Assistente Virtual </h3><p>Sugestão da melhor decisão pela inteligência artificial sendo elas premiações ou novas rodadas de negociação.</p></div>
      <!-- linha 2 -->
      <div  data-aos="fade" data-aos-duration="2500" data-aos-delay="500"><img alt="Comprador autônomo" src="../assets/ICON_Comprador@2x.png" class="icones" ><h3>Comprador autônomo </h3><p>Módulo para configurar as alçadas de autonomia e automatizar as sugestões do Assistente Virtual.</p></div>
      <div  data-aos="fade" data-aos-duration="2500" data-aos-delay="500"><img alt="Insights para o negócio" src="../assets/ICON_Insights@2x.png" class="icones" ><h3>Insights para o negócio  </h3><p>Indicadores de performance monitorados pela plataforma e que trazem insights para melhoria contínua do processo.</p></div>
    </div>
    </div>    
  </div>
  






</template>

<script>

export default {
  name: 'OQUE',
  props: {
    msg: String
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


#oquee-box {  min-height: 100vh; }
#oquee h1 { font-size: 6em; margin:1.6em auto .5em; padding-right: 1em; line-height: 1.1em; }
p.oque-sub { width: 60%; font-size: 1.2em;}
.logo {     height: 60px;    margin-left: 0;}

#oquee-box div { padding: 2em;}
#oquee-box img.icones { height: 45px;}
#oquee-box h3 { font-size: 1em; text-transform: uppercase; margin: 10px auto;}
#oquee-box div p { font-size: 1.1em; line-height: 1.2em;}

.img-solta-1 {
    position: absolute;
    margin-top: -41vh;
    width: 34%;
    left: 20%;
    transform: rotate(20deg);
}

@media ( max-width: 767.98px ) {

#oquee h1 {    font-size: 2.5em;    margin: 0.6em !important; }
p.oque-sub { margin: 1.6em !important; width: 85%; }
#oquee-box div {    padding: 0.5em 2em 0;}

}


</style>
