<template>
  <div id="inovacao" class="w-full bg-ocean">
    <div class="grid grid-cols-1 lg:grid-cols-2">
        <div id="inovacao-esq">
              <ul>
                <li class="bg-white text-ourblue text-left p-4 m-6" data-aos="slide-right">Realização de compras com negociações mais eficientes.</li>
                <li class="bg-white text-ourblue text-left p-4 m-6" data-aos="slide-right">Amplia a disponibilidade para análises e concepção de melhores estratégias.</li>
                <li class="bg-white text-ourblue text-left p-4 m-6" data-aos="slide-right">Elimina desvios de processos para garantia de compliance e das políticas internas.</li>
              </ul>          
        </div>

        <div id="inovacao-dir">
          <div class="grid grid-cols-1 lg:grid-cols-2">
            <div data-aos="slide-up"><h1 class="m-7 pt-22 lg:m-14 lg:pt-44 text-white">{{ msg }}</h1></div>
            <div>
              <ul class="mt-0 mb-8 px-8 lg:my-20 lg:py-20 text-white text-left">
                <li data-aos="fade-left">Melhores decisões de compra.</li>
                <li data-aos="fade-left">Escalabilidade para cotações e negociações.</li>
                <li data-aos="fade-left">Potencialização do capital humano.</li>
                <li data-aos="fade-left">Garantia do Cumprimento de Políticas e Procedimentos internos.</li>
                <li data-aos="fade-left">Insights de Suplemento à Estratégia.</li>
              </ul>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'inovacao-txt',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#inovacao {
    background: #fff url('../assets/INOVACAO_Bkg@2x.png') no-repeat center /cover;
}

#inovacao h1 {
    text-align: left;
    font-size: 3.2em;
    font-weight: 600;
    line-height: 1em;
}

#inovacao-esq {    background: #fff url('../assets/INOVACAO_Img-01@2x.jpg') no-repeat center /cover; height: 100vh;}
#inovacao-esq ul {    direction: rtl; padding-top: 15%; padding-right: 10%; }
#inovacao-esq ul li {
    width: 45%;
    font-size: 1.2em;
    direction: ltr;
}


#inovacao-dir ul li { margin: 10px 0; width: 60%;   font-weight: 600; }


@media ( max-width: 767.98px ) {
  
#inovacao-esq {    height: 70vh !important; }
#inovacao-esq ul li {    width: 45% !important;
    font-size: .91em;
    direction: ltr;
    line-height: 1em; }

#inovacao h1 {    font-size: 2.5em !important;}    
#inovacao-dir ul li {    width: 95% !important; }    

}

</style>