<template>
  <div id="cases" class="w-full bg-gray">
    <div class="grid grid-cols-1 lg:grid-cols-2">
        <div id="cases-esq">
          <h1 class="m-16 pt-10 lg:m-32 lg:pt-20 text-white"  data-aos="slide-left" >{{ msg }}</h1>           
        </div>

        <div id="cases-dir" class="m-6 pt-8 md:m-0 lg:m-16 lg:pt-20 text-left"  data-aos="slide-left" >
            <h2 class="text-white ml-0 lg:ml-20 mb-4">Setor da construção civil</h2>
            <p class="text-white ml-0 lg:ml-20">Empresa líder de mercado com atuação nacional e internacional com<strong> mais de mil usuários internos e mais de 26 mil itens processados, com Harpi gerou mais 
de 12 mil novas rodadas e mais de 10 mil requisições atendidas.</strong></p>

          <div id="cases-dir-box" class="grid grid-cols-3 bg-white text-ourblue text-center my-20 ml-0 lg:ml-20 p-4">
            <div>Saving médio de <br/><span>8,6%</span></div>
            <div>Redução da SLA em<br/><span>46,3%</span></div>
            <div>Ganho de produtividade de <br/><span>63,2%</span></div>
          </div>

        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'cases-txt',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#cases h1 {
text-align: right;
    font-size: 8em;
    font-weight: 600;
    line-height: 1.2em;
    margin-right: -60px;
    width: 80%;
}

#cases-esq {    background: #fff url('../assets/iStock-1320009809-(2)@2x.jpg') no-repeat center /cover; height: 100vh;     direction: rtl; }

#cases-dir h2 { font-size: 2em;    text-transform: uppercase; }
#cases-dir p {font-size: 16px; }

#cases-dir-box {    align-items: center; font-size: .75em; }
#cases-dir-box span { font-weight: 700; font-size: 2em;}



@media ( max-width: 767.98px ) {

#cases-esq {    height: 35vh; }
#cases h1 {    font-size: 2.5em !important;    margin-right: 50px;  }
#cases-dir h2 { font-size: 1.5em !important;   }

}

</style>