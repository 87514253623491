<template>
   <div id="quemsomos" class="container mx-auto mt-20">
    <div class="grid grid-cols-1 m-0 p-2 lg:grid-cols-2 lg:m-2 lg:p-4 text-left text-white">
      <div data-aos="fade-left"><h1 class="ml-6 mt-5 lg:ml-16 lg:mt-20">QUEM <br/> SOMOS</h1></div>
      <div data-aos="fade"><p class="my-3 ml-6 lg:my-6 pl-8 max-w-xl" v-html="msg"></p></div>
    </div>

    <img alt="QUEM SOMOS" src="../assets/iStock-1@2x.jpg" class="mt-5 lg:mt-20" data-aos="fade-zoom-in">
   <!-- imagem solta -->
    <img alt="" src="../assets/IMG-SOLTO_Azul-2@2x.png" class="img-solta-2">

    </div>    
</template>

<script>
export default {
  name: 'QUEMSOMOS',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#quemsomos h1 { font-size: 6em; line-height: 1.1em;}
.img-solta-2 {
    position: absolute;
margin-top: -35vh;
    width: 57%;
    left: 49%;
}


@media ( max-width: 767.98px ) {

#quemsomos h1  {    font-size: 2.5em !important;    margin: 0 .6em !important; }


}


</style>
